/* @font-face {
	font-family: 'dibitFont';
	font-style: normal;
	font-weight: normal;
	font-display: auto;
	src: url('http://dibit.s796.upress.link/wp-content/uploads/2020/08/Almoni-Tzar-DL-4.0-AAA-Bold.eot');
	src: url('http://dibit.s796.upress.link/wp-content/uploads/2020/08/Almoni-Tzar-DL-4.0-AAA-Bold.eot?#iefix') format('embedded-opentype'),
		url('http://dibit.s796.upress.link/wp-content/uploads/2020/08/Almoni-Tzar-DL-4.0-AAA-Bold.woff2') format('woff2'),
		url('http://dibit.s796.upress.link/wp-content/uploads/2020/08/Almoni-Tzar-DL-4.0-AAA-Bold.woff') format('woff'),
		url('http://dibit.s796.upress.link/wp-content/uploads/2020/08/Almoni-Tzar-DL-4.0-AAA-Bold.ttf') format('truetype'),
		url('http://dibit.s796.upress.link/wp-content/uploads/2020/08/Almoni-Tzar-DL-4.0-AAA-Bold.svg#dibitfont') format('svg');
} */

/* @font-face {
  font-family: 'Almoni';
  src: url('http://app.dibit.co.il/font/Almoni-Tzar-DL-4.0-AAA-Bold.eot');
  src: url('http://app.dibit.co.il/font/Almoni-Tzar-DL-4.0-AAA-Bold.eot?#iefix') format('embedded-opentype'),
		url('http://app.dibit.co.il/font/Almoni-Tzar-DL-4.0-AAA-Bold.woff2') format('woff2'),
		url('http://app.dibit.co.il/font/Almoni-Tzar-DL-4.0-AAA-Bold.woff') format('woff'),
		url('http://app.dibit.co.il/font/Almoni-Tzar-DL-4.0-AAA-Bold.ttf') format('truetype'),
		url('http://app.dibit.co.il/font/Almoni-Tzar-DL-4.0-AAA-Bold.svg') format('svg');
} */
@font-face {
  font-family: 'Almoni';
  src: url('./resources/font/Almoni-Tzar-DL-4.0-AAA-Bold.eot');
  src: url('./resources/font/Almoni-Tzar-DL-4.0-AAA-Bold.eot?#iefix') format('embedded-opentype'),
		url('./resources/font/Almoni-Tzar-DL-4.0-AAA-Bold.woff2') format('woff2'),
		url('./resources/font/Almoni-Tzar-DL-4.0-AAA-Bold.woff') format('woff'),
		url('./resources/font/Almoni-Tzar-DL-4.0-AAA-Bold.ttf') format('truetype'),
		url('./resources/font/Almoni-Tzar-DL-4.0-AAA-Bold.svg') format('svg');
}

@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

*, html, body {
  /* font-family: 'Rubik', sans-serif !important; */
  /* font-family: 'Almoni' !important; */
  font-family: 'Heebo', sans-serif;
  font-weight: 700;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
}

.public-DraftStyleDefault-ltr, .DraftEditor-editorContainer,
.RichTextEditor__editor___1QqIU, .public-DraftEditor-content, .RichTextEditor__editor___1QqIU .public-DraftEditorPlaceholder-root{
  direction: rtl !important;
  text-align: right !important;
}

.RichTextEditor__root___2QXK- {
  /* display: inline-block; */
}

html, body {
	background-color: #f2f2f2;
  overflow-x: hidden !important;
  max-width: 100% !important;
}

.carousel-outer .carousel .slide {
    background: none;
}

.carousel.carousel-slider .control-arrow {
  background-color: #07132D !important;
}

.phone_emulator {
	background: url('http://app.dibit.co.il/phone.png') no-repeat center;
	background-repeat: no-repeat;
  width: 200px;
  height: 500px;
}
iframe {
	width: 245px;
  height: 438px;
  margin-top: 39%;
  margin-left: 15%;
  border-radius: 5px;
}

.card__image {
	background-color: white;
	border-radius: 100%;
	width: 150px;
	padding: 15px;
	height: 150px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.image__img {
    left: 0;
    right: 0;
    top: 5;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
		margin-top: 15%;
}

/* .MuiGridListTile-tile {
  width: 130px !important;
  height: 100% !important;
} */

.halImg {
  /* border: 1px solid gray;
  filter: brightness(130%);
  -webkit-filter: brightness(130%);
  -moz-filter: brightness(130%);
  -o-filter: brightness(130%);
  -ms-filter: brightness(130%);
  -webkit-transition: all 0.50s;
  transition: all 0.50s; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

#time-picker {
  text-align: right !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: white;
  background-color: #07132D;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 1.3em;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.d_card_wrapper {
  background-color: white;
  width: 430;
  height: 100;
  box-shadow: 'rgba(0, 0, 0, 0.2) 0px 0px 2px 0px, rgba(0, 0, 0, 0.19) 0px 3px 5px 0px';
}

.d_card_content_flex {
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'space-between';
  margin-bottom: -20%;
  margin-top: -10%;
  padding-right: 15%;
  padding-left: 10%;
}

.d_card_content_flex_icon {
  color: #FEBE36;
  font-size: 3em;
  margin-top: 35%;
}

.d_card_content_flex_text {
  text-align: center;
  width: 45px;
  height: 45px;
  font-size: 2em;
  background-color: #FEBE36; /* yellow */
  color: #07132D; /* blue */
  border-radius: 50px;
}

.d_card_footer {
  text-align: center;
}

/* TABS */
.MuiTab-root {
  font-size: 1.5em !important;
  background-color: white !important;
  color: #07132D !important; /* blue */
}

.jss7 {
  background-color: #FEBE36 !important;
  color: #FEBE36 !important;
  height: 5px !important;
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: #FEBE36 !important;
  color: #FEBE36 !important;
  height: 5px !important;
}

.MuiTabs-centered {
  max-width: 90% !important;
}

.login__email_password {
  color: white !important;
  /* background-image: linear-gradient(0deg, rgba(7, 19, 45, 1) 50%, rgba(254, 190, 54, 1) 51%); */
  background-color: #3676fe !important;
}

.react-responsive-modal-modal {
  max-width: 90% !important;
  width: 100% !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-width: 250px !important;
  max-height: 250px !important;
}

.RichTextEditor__editor___1QqIU{
  font-size: 2em !important;
}

.signup__mobile__image__logo {
  width: 100%;
  height: auto;
  max-width: 150px;
}
.signup__mobile__image__cover {
  height: 100%;
  width: auto;
  max-height: 200px;
}


.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}
